import {useSelector} from 'react-redux'
import {RootState} from '../store'
import {IAPILocSettings} from '../services/ApiCallService'
import {constants} from "../config/constants";

function TextColorBasedBgLoction(LocSettings: IAPILocSettings) {
  if (LocSettings.appearance === 1 && LocSettings?.background_color == '#151520') {
    return 'text-white'
  } else if (LocSettings.appearance === 0 && LocSettings?.background_color == '#ffffff') {
    return 'text-black'
  }
}

function TextBorderColorBasedBgPCLoction(LocSettings: IAPILocSettings) {
  if (
    LocSettings.appearance === 1 &&
    LocSettings?.background_color == '#ffffff' &&
    LocSettings?.primary_color == '#ffffff'
  ) {
    return 'text-black border border-secondary'
  } else if (
    LocSettings.appearance === 0 &&
    LocSettings?.background_color == '#151520' &&
    LocSettings?.primary_color == '#151520'
  ) {
    return 'text-white  border border-secondary'
  }
}

function BtnCLEARClass(LocSettings: IAPILocSettings) {
  if (
    LocSettings.appearance === 1 &&
    LocSettings?.background_color == '#ffffff' &&
    LocSettings?.primary_color == '#ffffff'
  ) {
    return 'bg-clear-dark'
  } else {
    return 'bg-clear-white'
  }
}

export const useLocationBasedTextBorderColor = () => {
  const LocSettings = useSelector((state: RootState) => state.LocSettings.value)
  if (
    LocSettings.appearance === 1 &&
    LocSettings?.background_color == '#ffffff' &&
    LocSettings?.primary_color == '#ffffff'
  ) {
    return 'text-black border border-secondary'
  } else if (
    LocSettings.appearance === 0 &&
    LocSettings?.background_color == '#151520' &&
    LocSettings?.primary_color == '#151520'
  ) {
    return 'text-white  border border-secondary'
  }
}

const BtnColorBasedPCLoction = (LocSettings: IAPILocSettings) => {
  if (
    LocSettings.appearance === 1 &&
    LocSettings?.background_color == '#ffffff' &&
    LocSettings?.primary_color == '#ffffff'
  ) {
    return 'text-black'
  } else if (
    LocSettings.appearance === 0 &&
    LocSettings?.background_color == '#151520' &&
    LocSettings?.primary_color == '#151520'
  ) {
    return 'text-white '
  } else if (LocSettings.appearance === 0 && LocSettings?.primary_color == '#ffffff') {
    return 'text-black'
  } else if (LocSettings.appearance === 0 && LocSettings?.primary_color == '#151520') {
    return 'text-white'
  } else if (LocSettings.appearance === 1 && LocSettings?.primary_color == '#ffffff') {
    return 'text-white'
  } else if (LocSettings.appearance === 1 && LocSettings?.primary_color == '#151520') {
    return 'text-white'
  } else {
    return ''
  }
}

export const useLocationBasedBtnColor = () => {
  const LocSettings = useSelector((state: RootState) => state.LocSettings.value)
  if (
    LocSettings.appearance === 1 &&
    LocSettings?.background_color == '#ffffff' &&
    LocSettings?.primary_color == '#ffffff'
  ) {
    return 'text-black'
  } else if (
    LocSettings.appearance === 0 &&
    LocSettings?.background_color == '#151520' &&
    LocSettings?.primary_color == '#151520'
  ) {
    return 'text-white '
  } else if (
    LocSettings.appearance === 0 &&
    LocSettings?.primary_color == '#ffffff' &&
    LocSettings?.background_color == '#ffffff'
  ) {
    return 'text-black'
  } else if (
    LocSettings.appearance === 1 &&
    LocSettings?.primary_color == '#151520' &&
    LocSettings?.background_color == '#151520'
  ) {
    return 'text-white'
  } else if (LocSettings.appearance === 0 && LocSettings?.primary_color == '#ffffff') {
    return 'text-black'
  } else if (LocSettings.appearance === 0 && LocSettings?.primary_color == '#151520') {
    return 'text-white'
  } else if (LocSettings.appearance === 1 && LocSettings?.primary_color == '#ffffff') {
    return 'text-white'
  } else if (LocSettings.appearance === 1 && LocSettings?.primary_color == '#151520') {
    return 'text-white'
  } else {
    return ''
  }
}
const useLocationBasedNoBgBtnColor = () => {
  const LocSettings = useSelector((state: RootState) => state.LocSettings.value)
  if (
    LocSettings.appearance === 0 &&
    LocSettings?.background_color == '#ffffff' &&
    LocSettings?.primary_color == '#ffffff'
  ) {
    return 'transparent-btn-white-color'
  } else if (
    LocSettings.appearance === 1 &&
    LocSettings?.background_color == '#151520' &&
    LocSettings?.primary_color == '#151520'
  ) {
    return 'transparent-btn-black-color'
  } else if (
    LocSettings.appearance === 1 &&
    LocSettings?.primary_color == '#ffffff' &&
    LocSettings?.background_color == '#ffffff'
  ) {
    return 'transparent-btn-white-color'
  } else if (
    LocSettings.appearance === 0 &&
    LocSettings?.primary_color == '#151520' &&
    LocSettings?.background_color == '#151520'
  ) {
    return 'transparent-btn-black-color'
  } else if (LocSettings.appearance === 1 && LocSettings?.primary_color == '#ffffff') {
    return 'transparent-btn-white-color'
  } else if (LocSettings.appearance === 1 && LocSettings?.primary_color == '#151520') {
    return 'transparent-btn-black-color'
  } else if (LocSettings.appearance === 0 && LocSettings?.primary_color == '#ffffff') {
    return 'transparent-btn-black-color'
  } else if (LocSettings.appearance === 0 && LocSettings?.primary_color == '#151520') {
    return 'transparent-btn-black-color'
  } else {
    return ''
  }
}

const SVGColorBasedPCLoction = (LocSettings: IAPILocSettings) => {
  if (LocSettings.appearance === 1 && LocSettings?.primary_color == '#ffffff') {
    return 'svg-icon-dark'
  } else if (LocSettings.appearance === 0 && LocSettings?.primary_color == '#151520') {
    return 'svg-icon-white'
  } else {
    return 'svg-icon-primary'
  }
}

const SVGTextColorBasedPCLoction = (LocSettings: IAPILocSettings) => {
  if (LocSettings.appearance === 1 && LocSettings?.primary_color == '#ffffff') {
    return 'text-black'
  } else if (LocSettings.appearance === 0 && LocSettings?.primary_color == '#151520') {
    return 'text-white'
  } else {
    return 'text-primary'
  }
}

const useSVGTextColorBasedPCLoction = () => {
  const LocSettings = useSelector((state: RootState) => state.LocSettings.value)
  if (LocSettings.appearance === 1 && LocSettings?.primary_color == '#ffffff') {
    return 'text-black'
  } else if (LocSettings.appearance === 0 && LocSettings?.primary_color == '#151520') {
    return 'text-white'
  } else {
    return 'text-primary'
  }
}

const useSVGHeaderCartTextColorBasedPCLoction = () => {
  const LocSettings = useSelector((state: RootState) => state.LocSettings.value)

  if (
    LocSettings.appearance === 0 &&
    LocSettings?.primary_color == '#ffffff' &&
    LocSettings?.background_color == '#ffffff'
  ) {
    return 'text-black'
  }
  if (
    LocSettings.appearance === 1 &&
    LocSettings?.primary_color == '#151520' &&
    LocSettings?.background_color == '#151520'
  ) {
    return 'text-white'
  } else {
    return ''
  }
}
function isIPhone() {
  let userAgent = navigator.userAgent

  if (/android/i.test(userAgent)) {
    return false
  }

  return /iPad|iPhone|iPod/.test(userAgent)
}

const tsysDomain = (env: string) => {
  let url = 'https://gateway.transit-pass.com'
  if (env != 'Production') {
    url = 'https://stagegw.transnox.com'
  }
  return url
}

const softpointIframeDomain = (env: string) => {
  let url = 'https://iframe-gtw.softpoint.io'
  if(process.env.REACT_APP_MOBILE_ENV === constants.mobile_environments.staging) {
    url = 'https://stage-iframe-gtw.softpoint.io'
  }

  if (env != 'Production') {
    if(process.env.REACT_APP_MOBILE_ENV === constants.mobile_environments.live) {
      url = 'https://stage-iframe-gtw.softpoint.io'
    }
  }
  return url
}

const evertecIframeDomain = () => {
  let url = 'https://checkout.placetopay.com'
  return url
}

const capitalizeWords = (name: string): string => {
  const words = name.split(/\s+/)
  const capitalizedWords = words.map((word) => {
    const match = word.match(/^(\W*)([a-zA-Z]+)/)
    if (match) {
      const specialChars = match[1]
      const firstLetter = match[2].charAt(0)
      const restOfWord = match[2].slice(1)
      if (match[2] === match[2].toUpperCase()) {
        // Word is all caps, so capitalize only the first letter
        const capitalizedFirstLetter = firstLetter.toUpperCase()
        return `${specialChars}${capitalizedFirstLetter}${restOfWord.toLowerCase()}`
      } else if (firstLetter === firstLetter.toUpperCase()) {
        // Word is already capitalized, so return it unchanged
        return `${specialChars}${match[2]}`
      } else {
        // Capitalize the first letter and convert the rest of the word to lowercase
        const capitalizedFirstLetter = firstLetter.toUpperCase()
        return `${specialChars}${capitalizedFirstLetter}${restOfWord.toLowerCase()}`
      }
    } else {
      return word
    }
  })
  return capitalizedWords.join(' ')
}

const renderDisclaimer = (disclaimer: string, showAll: boolean) => {
  const disclaimerMessage = disclaimer.split(' ')
  if (showAll || disclaimerMessage.length <= 30) {
    return disclaimerMessage.join(' ')
  } else {
    return (
      <>
        {disclaimerMessage.slice(0, 30).join(' ')}
        <span>...</span>
      </>
    )
  }
}

export {
  TextColorBasedBgLoction,
  BtnColorBasedPCLoction,
  SVGColorBasedPCLoction,
  SVGTextColorBasedPCLoction,
  TextBorderColorBasedBgPCLoction,
  BtnCLEARClass,
  isIPhone,
  useSVGTextColorBasedPCLoction,
  tsysDomain,
  softpointIframeDomain,
  evertecIframeDomain,
  useSVGHeaderCartTextColorBasedPCLoction,
  capitalizeWords,
  renderDisclaimer,
  useLocationBasedNoBgBtnColor,
}
